<template>
  <div>
    <v-card class="d-flex justify-end mb-6" flat tile>
      <v-breadcrumbs :items="breadcrumbs" large>
        <template v-slot:divider>
          <v-icon>{{ icons.mdiSlashForward }} </v-icon>
        </template>
      </v-breadcrumbs>
    </v-card>
    <v-card flat class="pa-3 mt-2">
      <v-card-text>
        <v-card-title class="text-no-wrap pt-1 ps-2"> UPDATE CABANG</v-card-title>
        <form @submit.prevent="CreateData" ref="form">
          <v-row>
            <v-col cols="12" v-if="errors.length">
              <v-alert color="error" v-for="error in errors" :key="error" text class="mb-0">
                <div class="d-flex align-start">
                  <v-icon color="warning"> </v-icon>
                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">{{ error }}</p>
                  </div>
                </div>
              </v-alert>
            </v-col>

            <v-col md="6" cols="12">
              <v-text-field label="Nama Cabang" v-model="cabang.nama_cabang" dense outlined></v-text-field>
            </v-col>

            <v-col md="6" cols="12">
              <v-header>Aktif</v-header>
              <v-radio-group v-model="cabang.aktif" column>
                <v-radio label="Yes" value="Y" color="info"></v-radio>
                <v-radio label="No" value="T" color="error"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col cols="12">
              <v-btn type="submit" color="primary" class="me-3 mt-4"> Update </v-btn>

              <router-link :to="{ name: 'cabang-index' }">
                <v-btn color="secondary" outlined class="mt-4" type="reset"> Cancel </v-btn>
              </router-link>
            </v-col>
          </v-row>
        </form>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { mdiCheckBold, mdiSlashForward } from '@mdi/js'
export default {
  data() {
    return {
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/superadmin/dashboard',
        },
        {
          text: 'Cabang',
          disabled: false,
          href: '/superadmin/cabang',
        },
        {
          text: 'Update Cabang Barang',
          disabled: true,
        },
      ],

      icons: {
        mdiCheckBold,
        mdiSlashForward,
      },

      cabang: {
        nama_cabang: '',
        aktif: 'Y',
      },

      errors: [],

      page: 1,
      perPage: 10,
      pages: [],
      search: '',
      searchResult: [],
    }
  },
  mounted() {
    this.$isLoading(true)
    setTimeout(() => {
      this.getDataCabang()
      this.$isLoading(false)
    }, 2000)
  },
  methods: {
    getDataCabang() {
      // let uri = `/api/admin/datacabangaktif/${this.$route.params.id_cabang}/show`
      let uriUsers = process.env.VUE_APP_ROOT_API + `/api/superadmin/cabang/${this.$route.params.id_cabang}/show`
      this.axios.get(uriUsers).then(response => {
        this.cabang = response.data.cabangs
        // console.log(this.users.jenisbarangs)
      })
    },

    CreateData(e) {
      // console.log('id_cabang: ' + this.users.id_cabang)

      if (this.$data.cabang.nama_cabang.length === 0) {
        // this.errors.push('Nama Role harus diisi !')
        this.$swal.fire({
          title: 'Error',
          text: 'Nama cabang wajib diisi !',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1000,
        })
      } else {
        let uri = process.env.VUE_APP_ROOT_API + `/api/superadmin/cabang/${this.$route.params.id_cabang}/update`
        this.axios
          .post(uri, this.cabang)
          .then(response => {
            this.$swal.fire({
              title: 'Success',
              text: 'Cabang update successfully',
              icon: 'success',
              showCancelButton: false,
              showConfirmButton: false,
              timer: 1000,
            })
          })
          .catch(e => {
            this.$swal.fire({
              title: 'Error!',
              html: this.cabang.nama_cabang + ' sudah digunakan !',
              icon: 'error',
              showCancelButton: false,
              showConfirmButton: false,
              timer: 2000,
            })
          })

        e.preventDefault()
        setTimeout(() => {
          this.$router.push({ name: 'cabang-index' })
        }, 3000)
      }
    },
  },
  watch: {},
}
</script>
